<template>
  <div class="auth-footer">
    <Link
      :href="route('documents', {docType: 'pp'})"
      class="auth-footer__link"
    >
      Privacy Policy
    </Link>
    &nbsp;|&nbsp;
    <Link
      :href="route('documents', {docType: 'tou'})"
      class="auth-footer__link"
    >
      Terms of Use
    </Link>
  </div>
</template>

<script>
import { Link } from '@inertiajs/vue3';

export default {
  components: {
    Link
  },
};
</script>

<style lang="scss" scoped>
.auth-footer {
    @apply font-sans text-[14px] font-normal leading-[32px] tracking-[0px] text-[#0FAB6D] mt-auto mb-[10px];
    @screen lg {}
}

.auth-footer__link {
    @apply text-[#0FAB6D];
    &:hover {
        @apply underline;
    }
    @screen lg {}
}
</style>
